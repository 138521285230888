.beautiful-navbar {
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
  
    background-color: #A15D50 ;
    color:white;
    font-family: "Libre Bodoni", serif;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-style: normal;
    z-index: 2;

  }


  .responsive-navbar{
    color:white
  }
  
  .beautiful-navbar .navbar-nav .nav-link {
    color: #ffffff !important; /* Assure que le texte des liens est blanc */
}

.beautiful-navbar .navbar-brand {
    color: #ffffff !important; /* Assure que le texte du brand est blanc */
}