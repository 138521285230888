.apage {
  display: flex;
  flex-wrap: wrap;
  padding-top:20px;
  font-family: "Libre Bodoni", serif;
  width:100%
}

.header{
  padding-top:75px;
  display: flex;
}


h1{padding-top:50px;
display:flex;
max-width: fit-content;
margin-left: auto;
margin-right: auto;
font-size: xx-large;
}

.container {
  display: flex;
  align-items: center;  /* Alignement vertical */
  justify-content: space-between;  /* Espacement horizontal */
  flex-wrap: wrap;  /* Permet aux éléments de passer à la ligne en dessous sur les petits écrans */
}

.left-column, .right-column {
  flex: 1 1 50%;  /* Prend chacun 50% de la largeur de .container, avec un minimum initial de 50% */
  max-width: 100%;  /* Empêche les colonnes de dépasser 50% de la largeur */
  padding : 1%;
  position: relative;
  margin-top:2%;
}



@media (max-width: 768px) {
  .left-column, .right-column {
    flex-basis: 100%;  /* Chaque colonne prend toute la largeur sur les petits écrans */
    max-width: 100%;
  }
}


.leslieux, .lesfleurs{
  flex:0.8 0.2 80%;
  max-width: 80%;
}


.welcome{font-size:45px;}

.sectioninfo {
  margin-top:20px;
  display: flex;
  flex-wrap: wrap;  /* Allows the items to wrap as needed */
  align-items: center;  /* Vertically centers the items in the container */
  justify-content: space-between;  /* Horizontal spacing */
}

.pannel1, .pannel3 {
  flex: 0.8;  /* Gives these panels an equal share of the space */
}

.pannel2 {
  flex: 2.2;  /* Gives the middle panel twice as much space as the other two */

}

.prog{
  margin-bottom:15px
}


.lesfleurs {
  max-width:20%;
  transform: rotate(50deg);
}

.map {
  margin-top:2%;
  width: 100%; /* Utilisez une image ou un composant de carte interactif ici */
  height: 250px; /* Définissez une hauteur appropriée */
  background-color: #ccc; /* Placeholder pour la carte */
}

.locations-list {
  border-radius: 8px;
  /*box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
  border-color:  #A15D50;
  border-width: 7px;
  display: flex;
  align-items: center;  /* Alignement vertical */
  flex-wrap: wrap;
}

.imagefleur{transform: rotate(12deg);}

.corgi{
  margin-top:2%;
  margin-bottom:2%;
  margin-left:37%;
  width:30%;
  transform:scale(-1,1)};
  z-index:2000;

.wazo{
  padding-left:25%;
  width:100%;
}

.papillon{
  padding-left:25%;
  width:100%;

}

.leslieux{padding-top: 15%;}

.highlight{color:#A15D50}

/* Ajoutez ce style pour l'effet de survol sur les éléments cliquables */
.clickable:hover {
  cursor: pointer;

}

/* Si vous souhaitez également avoir une transition douce pour l'effet */
.clickable {
  transition: color 0.3s, text-decoration 0.3s;
}