.slug-container {
  display: flex;
  flex-direction: column;
  align-items: center;  /* Centre les éléments horizontalement */
  justify-content: center; /* Centre les éléments verticalement sur la page */
  min-height: 100vh; /* Assure que le conteneur prend au moins la hauteur de la fenêtre */
  padding: 5px; /* Ajoute un padding pour ne pas coller aux bords de l'écran */
  font-family: "Libre Bodoni", serif;

}

.slug-box {
  width: 100%; /* Prend toute la largeur jusqu'à 500px */
  max-width: 500px; /* Largeur maximale de la boîte */
  padding: 7px;
  background: #A15D50; /* Couleur de fond */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
  margin-top: 5px; /* Espacement avec l'élément au-dessus */
}

input {
  width: 100%; /* Permet à l'input de prendre toute la largeur de son conteneur */
  max-width: 300px; /* Largeur maximale de l'input */
  padding: 10px;
  margin: 10px 0; /* Ajoute de l'espace autour de l'input */
}

.button-elegant {
  padding: 10px 20px;
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.button-elegant:hover {
  background-color: lightgrey;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.corg {
  width: 100%; /* Assure que l'image SVG prend la largeur du conteneur */
  max-height: 350px; /* Limite la taille de l'image pour qu'elle ne soit pas trop grande */
  margin-top: 20px; /* Espacement avec l'élément au-dessus */
}

.corg {
  width: 100%; /* Assure que l'image SVG prend la largeur du conteneur */
  max-width: 500px; /* Limite la taille de l'image pour qu'elle ne soit pas trop grande */
  margin-top: 20px; /* Espacement avec l'élément au-dessus */
}



.slug-text {
padding:10px
}
