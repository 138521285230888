
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Utilise toute la hauteur de la vue */
    background: url('https://i.postimg.cc/5NLp5Knn/PORTRAIT-ANNESO-JEAN-BD.jpg') no-repeat center center fixed;
    background-size: cover;
    z-index: -1;
  }

  .login-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.4); /* Couleur blanche avec une opacité de 50% */
    z-index: -0.5;
}
  
  .login-box {

    font-family: "Libre Bodoni", serif;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8); /* Blanc avec transparence */
    border-radius: 8px;
    width: 100%;
    max-width: 500px; /* Largeur maximale de la boîte */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    text-align: center;
    z-index: 1;
  }
  .ASJ{
    color:#A15D50;
    font-family: "YourFontName" ;
    font-weight: bolder;
    font-style: bold;
    font-size: 55px;
  }
  
  @media (max-width: 768px) {
    .login-box {
      padding: 15px;
    }
  }

  .button-elegant {
    padding: 10px 20px;
    margin-left: 10px;
    font-size: 16px;
    color: black;
    background-color: white; /* Couleur de fond bleu doux */
    border: none;
    border-radius: 5px; /* Bords arrondis */
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .button-elegant:hover {
    background-color: lightgrey; /* Changement de couleur au survol */
    box-shadow: 0 5px 15px rgba(0,0,0,0.2); /* Ombre portée pour un effet de profondeur */
  }
  