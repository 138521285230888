/* Container styling */
.rsvpcontainer {
    padding: 20px;
    background-color: #f9f9f9; /* Light grey background */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    justify-content: center;
    font-family: "Libre Bodoni", serif;
}

/* Header styling */
h1, h2 {
    color: #333; /* Dark grey color for text */
    font-family: 'Arial', sans-serif; /* Using Arial as an example sans-serif font */
    margin-bottom: 30px;
    font-family: "Libre Bodoni", serif;}

.rsvph2{
    padding-top: 20px;
    padding-bottom: 10px;
}    


.Col{
    justify-content: center;
}

.rsvprow
{justify-content: center;
width: 100%;}


/* Form styling */
.form-control {
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 16px; /* Slightly larger font size for readability */
}

.form-control:focus {
    border-color: #A15D50; /* Blue border on focus */
    box-shadow: 0 0 5px rgba(0,86,179,0.25); /* Glowing effect to indicate focus */
}

.form-label {
    font-size: 14px;
    color: #666; /* Medium grey color for text */
    margin-bottom: 5px;
}

/* Invalid feedback styling */
.form-control.is-invalid,
.form-control.is-invalid:focus {
    border-color: #dc3545; /* Red border for errors */
    background-image: none; /* Removing default background image */
}

.invalid-feedback {
    color: #dc3545; /* Red text for error messages */
}

/* Button styling */
.container .button-primary {
    background-color: #A15D50; /* Blue background */
    border-color: #704138; /* Darker blue border */
    color: white; /* White text */
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for hover effects */
    margin-top: 35px;
}

s
button {
    background-color: #A15D50; /* Blue background */
    border-color: #704138; /* Darker blue border */
    color: white; /* White text */
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for hover effects */
}

.container .button-primary:hover {
    background-color: #704138; /* Darker blue on hover */
    border-color: #704138; /* Even darker blue border on hover */
}
