/* Vous pouvez personnaliser le CSS selon vos besoins */
.wlistcontainer {
    font-family: "Libre Bodoni", serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh;
    z-index: -1;
  }

  .wlistbox{    font-family: "Libre Bodoni", serif;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    flex-direction: column;  /* Stack children vertically */
    display: flex;
    align-items: center;     /* Center-align the items horizontally */
    gap: 20px;               /* Space between elements */
    width: 66%;

  }

  .wazo1{max-height: 200px;}
