.button-primary {
    background-color: #A15D50; /* Blue background */
    border-color: #704138; /* Darker blue border */
    color: white; /* White text */
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for hover effects */
    margin-top: 10px;
}
  

  
  /* Pour centrer le conteneur principal dans la page */
  .wlistbox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  
  /* Styles pour le conteneur principal */




.password-info {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}
/* Styles pour le conteneur principal */

  

/* Styles pour le conteneur principal */
.photo-blocks-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
  }
  
  /* Styles pour chaque bloc de photos */
  .photo-block {
    background-color: #f9f9f9; /* Couleur de fond légèrement grise */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Ombre légère */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 300px; /* Largeur du bloc */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centre verticalement le contenu du bloc */
    align-items: center; /* Centre horizontalement le contenu du bloc */
    height: 200px; /* Hauteur définie pour les blocs */
  }
/* Styles pour chaque bloc de photos */
